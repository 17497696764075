<template>
  <div class="dis-con">
    <template v-if="initDiscountArr.length > 0">
      <div v-for="(item, index) in initDiscountArr" :key="index">
        <Cell v-show="item == '1'">
          <template #title>
            <img class="dis-img" src="@static/vip/jianglijin.png" alt />
            <span>{{item | dis_name}}</span>
            <span class="dis-money point-dis">({{disAllMoney.user.point||deductIntegral}}积分)</span>
          </template>
          <template #right-icon>
            <span class="dis-money">抵扣{{disAllMoney.point_dec_money}}</span>
          </template>
        </Cell>
        <Cell v-show="item == '2' && coupon_nums != 0" @click="openCouponsList">
          <template #title>
            <img class="dis-img" src="@static/vip/youhuiquan.png" alt />
            <span class="custom-title">优惠券</span>
            <span>(可用{{coupon_nums}}张)</span>
          </template>
          <template #default>
            <div class="used-coupon">
              <span>({{used_coupon}}张)</span>
              <span>{{bottom_ticket}}</span>
            </div>
          </template>
        </Cell>
        <Cell v-show="item == '3'">
          <template #title>
            <img class="dis-img" src="@static/vip/te.png" alt />
            <span class="custom-title">会员优惠</span>
          </template>
          <template #right-icon>
            <span class="dis-money">{{vip_discounts}}</span>
          </template>
        </Cell>
      </div>
    </template>
    <div>
      <span class="dis-title" v-if="userDiscountArr.length > 1">以下优惠不可同享</span>
      <RadioGroup v-model="now_dis">
        <CellGroup>
          <div v-for="(item, index) in  userDiscountArr" :key="index">
            <Cell v-show="item == '1'" clickable @click="now_dis = '1'">
              <template #title>
                <img class="dis-img" src="@static/vip/jianglijin.png" alt />
                <span>{{item | dis_name}}</span>
                <span class="dis-money point-dis">({{disAllMoney.user.point}}积分)</span>
              </template>
              <template #right-icon>
                <span class="dis-money">抵扣{{disAllMoney.point_dec_money||deductIntegral}}</span>
                <Radio :name="item" />
              </template>
            </Cell>
            <Cell v-show="item == '2'"  clickable @click="now_dis = '2', openCouponsList()">
              <template #title>
                <img class="dis-img" src="@static/vip/youhuiquan.png" alt />
                <span class="custom-title">优惠券</span>
                <span>(可用{{coupon_nums}}张)</span>
              </template>
              <template #right-icon>
                <div class="used-coupon">
                  <span>({{used_coupon}}张)</span>
                  <span>{{bottom_ticket}}</span>
                </div>
                <Radio :name="item" />
              </template>
            </Cell>
            <Cell v-show="item == '3'" clickable @click="now_dis = '3'">
              <template #title>
                <img class="dis-img" src="@static/vip/te.png" alt />
                <span class="custom-title">会员优惠</span>
              </template>

              <template #right-icon>
                <span class="dis-money">{{vip_discounts}}</span>
                <Radio :name="item" />
              </template>
            </Cell>
          </div>
        </CellGroup>
      </RadioGroup>
    </div>
  </div>
</template>

<script>
import { Cell, RadioGroup, Radio, CellGroup } from "vant";
import { mapGetters } from 'vuex';
import { setMoney } from "@/utils/tools";
import setting from "@/api/setting";
export default {
  components: {
    Cell,
    RadioGroup,
    Radio,
    CellGroup
  },
  props: {
    nowRadio: {
      type: String
    },
    money: {
      type: String
    },
    open_point: {
      type: Boolean,
      default: true
    },
    coupon_nums: {
      type: Number
    },
    used_coupon: {
      type: [String, Number]
    },
    bottom_ticket: {
      type: String
    },
    vip_discounts: {
      type: String
    },
    deductIntegral: {
      type: [String, Number]
    },
    couponIds: {
      type: String
    }
  },
  computed: {
    ...mapGetters(['initDiscountArr', 'now_UserDisKey', 'userDiscountArr', 'disAllMoney']),
  },
  watch: {
    nowRadio: function(val) {
      this.now_dis = val;
    },
    now_dis: async function(val) {
      // 更新优惠信息
      const data = this.$parent.all_disinfo(this.money, this.couponIds, this.used_coupon, val);
      await this.updateNowDismoney(data);
      if (val && this.disAllMoney) {
        const obj = setMoney(this.initDiscountArr.concat(val), this.money, this.disAllMoney);
        this.$parent.discountsPrice = obj.dis_money;
        this.$parent.payPrice = obj.end_money;
        this.$store.commit('updateDisMoney', { now_dis: val, money: this.money });
      }
      this.$store.commit('setNowUserKey', val);
    }
  },
  filters: {
    dis_name: function(val) {
      if (val) {
        switch (val) {
          case '1':
            return "积分优惠"
          case '2':
            return "优惠券"
          case '3':
            return "会员优惠"  
        }
      }
    }
  },
  data() {
    return {
      now_dis: ""
    }
  },
  methods: {
    async updateNowDismoney(data) {
      try {
        const res = await setting.getCompute(data);
        if (res.code === 200) {
          this.$store.commit('updateAllDisMoney', res.data);
          return res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 优惠券
    openCouponsList() {
      this.$parent.openCouponsList();
    }
  }
}
</script>

<style lang="scss" scoped>
$textcolor: #ff414d;
.dis-con {
  width: 100%;
  height: auto;
  padding: 0 10px;
  margin-top: 10px;
}
.dis-title {
  font-size: 14px;
  color: $textcolor;
  line-height: 30px;
}
.dis-money {
  color: $textcolor;
  margin-right: 10px;
}
.point-dis {
  color: #696969;
  font-size: 14px;
  margin-left: 6px;
}
.used-coupon {
  color: $textcolor;
}
.dis-img {
  width: 12.5px;
  margin-right: 8px;
}
</style>