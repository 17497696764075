<template>
  <div>
    <div class="oils-infor">
      <div class="oils-infor-com">
        <div class="oils-infor-com-name">
          <img src="@static/vip/youpin.png" alt />
        </div>
        <div class="oils-infor-com-Cont">{{nowOilInfor.goods.name}}</div>
      </div>
      <div class="oils-infor-com" v-if="goodslist.length > 1">
        <div class="oils-infor-com-name">
          <img src="@static/vip/youqing.png" alt />
        </div>
        <div class="oils-infor-com-Cont" @click="oilStatus = true">{{nowOilInfor.name}}</div>
      </div>
      <div class="oils-infor-com" v-else>
        <div class="oils-infor-com-name">
          <img src="@static/vip/youqing.png" alt />
          <span>油枪</span>
        </div>
        <div class="oils-infor-com-Cont">{{nowOilInfor.name}}</div>
      </div>
    </div>
    <!-- 选着加油类型弹窗 -->
    <ActionSheet v-model="oilStatus" title="选择油枪" :round="false" style="min-height: 43%" :close-on-click-overlay="false">
      <div class="oil-all">
        <div
          :class="[ nowOilInfor.name == item.name ? 'oil-active':'', 'oil-type' ]"
          v-for="(item, idx) in goodslist"
          :key="idx"
          @click="changeoils(item)"
        >
          <span>{{item.goods.name}}{{item.goods.type.name}}</span>
          <span>{{item.name | change_name}}</span>
        </div>
      </div>
    </ActionSheet>
  </div>
</template>
<script>
import CreditShop from "@/api/creditshop";
import { ActionSheet } from "vant";
export default {
  components: {
    ActionSheet
  },
  props: [
    "my_cardInfor",
    "goodslist",
    "inputPrice",
  ],
  filters: {
    change_name(val) {
      return val.split('油')[0];
    }
  },
  data() {
    return {
      nowOilInfor: { //当前选中油的所有信息
        id: "",
        name:"",
        goods: {},
      }, 
      oilStatus: false, // 油枪选项
      vipCard_id: ""
    };
  },
  methods:{
    changeoils(item) {
      let info = {
        card_id: this.vipCard_id ? this.vipCard_id:sessionStorage.getItem('vipCard_id'),
        type_id: item.goods.type_id,
        goods_id: item.goods.id
      }
      this.getoilsInfor(item, info);
      this.oilStatus = false;
    },
    // 设置加油信息
    async getoilsInfor(item, info) {
      this.nowOilInfor = item
      this.$parent.nowOilInfor = item //更改父组件元素
      this.vipCard_id = info.card_id;
      // 计算加油量
      this.$parent.refuelQuantity = this.$formCode.round((this.inputPrice/this.nowOilInfor.goods.price), 2);
      await this.getDiscount(info);
      if (this.inputPrice>0) {
        this.$parent.changeOilFind();
      }
    },
    async changePrice(price) {
      await this.getDiscount({
        card_id: this.vipCard_id,
        type_id: this.nowOilInfor.goods.type_id,
        goods_id: this.nowOilInfor.goods.id
      },price)
    },
    // 获取当前优惠项
    async getDiscount(info, price = null) {
      try {
        const { card_id, type_id, goods_id } = info;
        const res = await CreditShop.getDiscount(card_id, type_id, goods_id, price?price:this.inputPrice);
        if(res.code == 200) {
          this.$parent.discountStatus = res.data.status;
          switch (res.data.status) {
            case 1:
              this.$parent.vip_discounts = res.data.zhe/10 + "折";
              this.$parent.vip_discount_cont = res.data.zhe/10;
              break;
            case 2:
              this.$parent.vip_discounts = "每升-" + res.data.money + "元";
              this.$parent.vip_discount_cont = res.data.money;
              break;
            case 3:
              this.$parent.vip_discounts = "-" + res.data.every.discount + "元";
              this.$parent.vip_discount_cont = res.data.every.discount;
              break;
            case 4:
              this.$parent.vip_discounts = "每升减优惠-" + res.data.every.discount + "元";
              this.$parent.vip_discount_cont = res.data.every.discount;
              break;
            default:
              this.$parent.vip_discounts = "暂无优惠";
              this.$parent.vip_discount_cont = 10;
              break;
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
  }
};
</script>


<style lang="scss" scoped>
.oils-infor {
  width: 100%;
  margin-top: 9px;
  height: auto;
  padding: 4px 12px;
  background: #fff;
  display: flex;
  align-items: center;
  .oils-infor-com {
    flex: 1;
    display: flex;
    align-items: center;
    .oils-infor-com-name {
      display: flex;
      align-items: center;
      img {
        width: 28px;
        margin-right: 8px;
      }

      span {
        font-size: 14px;
        color: rgba(153, 153, 153, 1);
      }
    }

    .oils-infor-com-Cont {
      height: 20px;
      line-height: 20px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(31, 31, 31, 1);
    }
  }
}
.oil-all {
  display: flex;
  flex-wrap: wrap;
  // padding: 10px 0;
  justify-content: flex-start;
  overflow: auto;
  // padding-bottom: 0;
  .oil-type,
  .oil-active {
    width: 124.6px;
    min-height: 70px;
    text-align: center;
    display: flex;
    padding: 10px 18px;
    font-size: 16px;
    color: #000000;
    border: 1px solid #f1f1f1;
    flex-direction: column;
    justify-content: space-between;
    span:nth-child(2),
    span:nth-child(3) {
      font-size: 16px;
      color: #ff9966;
      font-weight: 560;
    }
  }
  .oil-active {
    border: 1px solid #ff9966;
  }
}
</style>
