<template>
  <div class="box">
    <div class="newConfirmOrder">
      <div class="shop-infor">
        <div class="shop-infor-name">{{allInfor.room_name}}</div>
        <div class="shop-infor-address">{{allInfor.room_area}}</div>
        <div class="shop-infor-price">
          ￥
          <span>{{nowOilInfor.goods.price}}</span>
        </div>
        <div class="shop-infor-head">
          <img v-lazy="allInfor.room_image" alt />
        </div>
      </div>

      <oilInfor
        ref="oilInfor"
        :my_cardInfor="my_cardInfor"
        :goodslist="goodslist"
        :inputPrice="inputPrice"
      ></oilInfor>

      <div class="oils-Cont">
        <div class="oils-Cont-title">
          <span>加油金额</span>
          <Button to="/oilsCardList" type="primary" size="small">去充值</Button>
        </div>
        <div class="oils-Cont-price">
          <span v-show="inputPrice">￥</span>
          <input
            type="number"
            v-model="inputPrice"
            @blur="onBlurInput()"
            placeholder="请输入加油金额"
            :class="inputPrice?'':'inputSize'"
          />
          <span>可加{{refuelQuantity}}L</span>
        </div>
        <div class="oils-Cont-discounts">
          <div
            v-for="(item, index) in selectPriceData"
            :key="index"
            class="discounts-optiion"
            @click="selectPrice(item,index)"
          >
            <span class="price">¥{{item}}</span>
            <span
              class="discounts-optiion-price"
              v-if="discountStatus==1 && (item * (1- vip_discount_cont /10)) != 0"
            >优惠¥{{ Number((item * (1- vip_discount_cont /10)).toFixed(2))}}</span>
            <span
              class="discounts-optiion-price"
              v-else-if="discountStatus==2 && (Number(item/nowOilInfor.goods.price) * vip_discount_cont*100)/100 != 0"
            >优惠¥{{Math.round(Number(item/nowOilInfor.goods.price) * vip_discount_cont*100)/100}}</span>
          </div>
        </div>
      </div>

      <userDismoney 
        ref="dis_money" 
        class="animated fadeInUp"
        :nowRadio="dis_radio" 
        :money="inputPrice" 
        :coupon_nums="is_used_num"
        :used_coupon="useNum"
        :bottom_ticket="bottom_ticket"
        :vip_discounts="vip_discounts"
        :deductIntegral="deductIntegral"
        :couponIds="couponIds"
      > 
      </userDismoney>

      <cell class="dis-fa" v-if="receipt_button" value="无可用优惠券" title="开发票" label="余额、油卡将不增开发票" center>
        <div class="cell_lft" slot="title">
          <span class="iconfont iconzhinengkefu-"></span>
          <span class="custom-title">开发票</span>
        </div>
        <div slot="default" class="select-jifen">
          {{ receiptCont }}
            <SwitchBill v-if="nowtypeIdx == '0'" v-model="bill_status" size="18" />
            <span v-if="nowtypeIdx == '1'" @click="now_bill_status = true">个人发票 ></span>
            <span v-if="nowtypeIdx == '2'" @click="now_bill_status = true">企业发票 ></span>
          <icon @click="cancelInvoice" v-if=" receiptCont" name="close" />
        </div>
      </cell>

      <coupon
        ref="coupon"
        :inputPrice="inputPrice"
        :allInfor="allInfor"
        :nowOilInfor="nowOilInfor"
        :openMutex="openMutex"
        :offline_buy="offline_buy"
      ></coupon>

      <orderPay
        ref="orderPay"
        :nowOilInfor="nowOilInfor"
        :allInfor="allInfor"
        :payPrice="payPrice"
        :discountsPrice="discountsPrice"
        :deductIntegral="deductIntegral"
        :ruleAmount="ruleAmount"
        :inputPrice="inputPrice"
        :couponIds="couponIds"
        :receipt_id="receipt_id"
        :qrcode_id="qrcode_id"
        :room_id="allInfor.room_id"
        :recharge_state="recharge_state"
        :useNum="useNum"
        :bill_status="bill_status"
      ></orderPay>
    </div>
    <Popup round v-model="now_bill_status" position="bottom" style="width=100%">
      <Invoice @set_receipt="setBillId" style="min-width:375px"/>
    </Popup>
    <div class="submit-order">
      <div class="discounts-price">已优惠 ¥{{discountsPrice}}</div>
      <div class="pay-price-operation">
        <span>合计：</span>
        <span class="fontSize22">¥{{payPrice}}</span>
        <div class="submit" :class="inputPrice?'':'nomoney'" @click="goPay">去支付</div>
      </div>
    </div>
  </div>
</template>
<script>
import UserInfo from "@/api/user";
import CreditShop from "@/api/creditshop";
import Invoice from "../child/invoiceInvoice.vue";
import {  Dialog, Popup, Button, Cell, Switch } from "vant";
import oilInfor from "./payChild/oilInfor";
import coupon from "./payChild/coupon";
import wx from "weixin-js-sdk";
import { goWxConfigJsApi } from "@/utils/wxpay";
import { mapGetters } from 'vuex';
import userDismoney from "./payChild/userDismoney";
import Utils from "@/lin/utils/util";
export default {
  components: {
    orderPay: () => import("./orderPay.vue"),
    Cell,
    // Checkbox,
    oilInfor,
    coupon,
    Popup,
    Button,
    SwitchBill: Switch,
    userDismoney,
    Invoice
  },
  computed: {
    ...mapGetters(['initDiscountArr', 'now_UserDisKey', 'userDiscountArr', 'disAllMoney']),
  },
  data() {
    return {
      bill_status: false,
      payType: [],
      couponIds: "", //优惠券id串
      useNum: 0, //使用券的数量
      isCanScroll: false,
      store_id: "",
      room_id: "",
      qrcode_id: "",
      type: "", //进入类型
      showCop: false,
      useIntegral: false,
      openMutex: null, //是否开启互斥
      vipCard_id: "",
      allInfor: {
        qrcode_id: "",
        store_id: 0,
        room_id: 22,
        point_set: {},
        recharge_set: {},
        preferce_set: {},
        wallet: {}
      }, // 可能用到的全部信息
      goodslist: [],
      dis_radio: "",
      my_cardInfor: {}, // 会员卡信息
      discountStatus: 0, //优惠状态
      nowtypeIdx: "0", // 发票类型 0 无 1 个人 2企业
      now_bill_status: false, // 开票内容展示
      vip_discounts: "", // 使用折扣卡的优惠内容
      vip_discount_cont: 0, //具体优惠数据
      selectPriceData: ["100", "200", "300", "400"], //点击选择单价
      priceIdx: "-1", // 选择已有价格
      nowOilInfor: {
        //当前选中油的所有信息
        id: "",
        name: "",
        goods: {}
      },
      radio: "",
      receipt_button: false, // 是否开票
      newPay: false, // 默认支付方式
      inputPrice: "", //输入框金额
      payPrice: "0.00", // 实际支付金额
      discountsPrice: 0, // 优惠金额
      refuelQuantity: "0.00", // 预计加油量
      vip_youhui_price: 0, // 会员折扣之后减掉的金额
      deductIntegral: 0, //积分优惠
      ruleAmount: 0, //一积分抵扣多少钱
      ruleRate: 0, //最多可以抵扣
      ruleMin: 0, //多少元起可以,最低多少元
      upPrice: 0, // 上一次优惠券金额,
      bottom_ticket: `选择优惠券`,
      is_used_num: 0,
      now_ticketId: "",
      receiptCont: "", // 发票抬头
      recharge_state: true, // 未设置储值支付时不显示该选项
      receipt_id: "", // 发票id
      submitState: true, //提交状态,
      offline_buy: "" // 默认优惠互斥
    };
  },
  watch: {
    now_UserDisKey(disKey) {
      this.dis_radio = disKey;
    },
    disAllMoney: {
      handler: function(val) {
        if (val) {
          this.discountsPrice = val.dis_money;
          this.payPrice = val.end_money;
        }
      },
      deep: true
    },
    bill_status(status) {
      this.now_bill_status = status;
    },
    now_bill_status(status) {
      if (!this.receipt_id && !status) {
        this.bill_status = false;
      }
    },
    inputPrice(money) {
      this.updateInput(money);
    },
    useIntegral(val) {
      if (val) {
        if (Number(this.inputPrice) > 0) {
          this.getUseIntegral();
        } else {
          this.$toast("请您输入大于0的金额");
          this.useIntegral = false;
        }
      } else {
        this.deductIntegral = 0;
        this.$refs.orderPay.upIntegral = "";
      }
    }
  },
  created() {
    this.MygetVipList();
    if (this.isWeixin_status) {
      goWxConfigJsApi("hideMenuItems");
      setTimeout(() => {
        wx.hideMenuItems({
          menuList: [
            "menuItem:copyUrl",
            "menuItem:share:timeline",
            "menuItem:share:appMessage"
          ]
        });
      }, 500);
    }
    if (this.$route.query.hasOwnProperty("type")) {
      this.store_id = this.$route.query.store_id;
      this.room_id = this.$route.query.room_id;
      this.type = this.$route.query.type;
      this.qrcode_id = "";
      if (String(this.room_id).indexOf("?") != -1) {
        this.room_id = String(this.room_id).slice(
          0,
          String(this.room_id).indexOf("?")
        );
      }
    } else {
      this.qrcode_id = localStorage.getItem("qrcode_id");
    }
    setTimeout(() => {
      this.getoilType();
    }, 100);
  },

  async mounted() {
    const configArr = await this.getAppConfig();
    configArr.forEach(item => {
      if (item.name == "receipt_button") {
        this.receipt_button = item.status;
        return;
      }
    });
    this.$nextTick(() => {
      this.getInvoiceInfor();
    }, 300);
  },
  methods: {
    updateInput: Utils.debounce(async function(val) {
      const that = this;
      if (isNaN(val)) {
        that.$toast("请输入数字");
        that.inputPrice = "";
      } else {
        if (val.indexOf(".") != -1) {
          that.inputPrice = val.substring(0, val.indexOf(".") + 3);
        }
        that.useIntegral = false; // 重置积分抵扣
        if (val) {
          // 查找优惠券
          let str = that.$store.state.now_UserDisKey;
          console.log(that.$store.state.now_UserDisKey)
          await that.$refs.coupon.getCoupons(0);
          await that.$refs.oilInfor.changePrice(val);
          if (!str) {
            if (that.offline_buy) {
              str = that.offline_buy.split(',');
              str = str[0];
            } else {
              str = '1, 2, 3'
            }
          }
          that.calculatePrice(that.inputPrice, that.couponIds, that.useNum, str);
        } else {
          that.$refs.coupon.nonuseCoupon();
        }
      }
    }, 100),
    setBillId(id, type) {
      this.receipt_id = id;
      this.nowtypeIdx = type;
      this.now_bill_status = false;
    },
    async cash_order_status() {
      try {
        const res = await CreditShop.cash_order_status();
        if (res.code == 200) {
          if (res.data.data) {
            this.$dialog
              .alert({
                message: "您有未确认订单，请联系加油员进行确认!",
                confirmButtonText: "返回"
              })
              .then(() => {
                this.$router.replace("/vip_mycenter");
              });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 获取使用积分
    async getUseIntegral() {
      try {
        const res = await CreditShop.getUseIntegral(
          this.nowOilInfor.goods.id,
          this.inputPrice,
          "balance"
        );
        if (res.code == 200) {
          if (res.money == 0) {
            this.$toast(res.msg);
            this.useIntegral = false;
          } else {
            this.deductIntegral = res.money;
            this.$refs.orderPay.upIntegral = parseInt(res.maxUsePoint);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    changeOilFind() {
      this.useIntegral = false; // 重置积分抵扣
      this.$refs.coupon.nonuseCoupon();
      this.$refs.coupon.getCoupons(0); // 查找优惠券
      this.calculatePrice(this.inputPrice);
    },
    onBlurInput() {
      window.scroll(0, 0);
    },
    // 开发票
    openInvoice() {
      this.$router.push({
        path: "/invoice_mag",
        query: {
          source: "order"
        }
      });
    },
    cancelInvoice() {
      this.receipt_id = "";
      this.receiptCont = "";
      this.$store.commit("setInvoiceId", "");
    },
    // 发票
    async getInvoiceInfor() {
      try {
        if (this.$store.state.user.invoiceId) {
          const res = await CreditShop.invoiceIdDetail(
            this.$store.state.user.invoiceId
          );
          this.receipt_id = res.data.id;
          this.receiptCont = `发票抬头-${res.data.tax_name}`;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 去支付
    goPay() {
      if (this.inputPrice) {
        if (this.payPrice > 0) {
          this.$refs.orderPay.showpay = true;
        } else {
          this.$toast("请重新输入金额");
        }
      } else {
        this.$toast("请输入金额");
      }
    },
    all_disinfo(money, coupon_id, coupon_num, now_dis) {
      if (money) {
        let config_dis = "";
        if (this.offline_buy) {
          now_dis = now_dis ? now_dis:this.now_UserDisKey;
          config_dis = this.initDiscountArr.concat(now_dis).toString();
          console.log(this.initDiscountArr)
        } else {
          config_dis = "1,2,3"
        }
        const data = {
          account_id: "",
          cate_id: this.nowOilInfor.id,
          coupon_type: 1,
          dis_arr: config_dis,
          key: "",
          money: money,
          point: this.allInfor.wallet.point,
          coupon_id: coupon_id,
          coupon_num: coupon_num,
          qrcode_id: this.qrcode_id,
          receipt: 0,
          receipt_id: "",
          recharge: 0,
          recharge_id: "",
          room_id: this.room_id
        }
        return data;
      }
    },
    // 计算数据
    calculatePrice(money, coupon_id, coupon_num, now_dis) {
      this.refuelQuantity = this.$formCode.round((this.inputPrice / this.nowOilInfor.goods.price), 2);
      const data = this.all_disinfo(money, coupon_id, coupon_num, now_dis);
      this.$store.dispatch('computeMoney', data);
    },
    selectPrice(item, index) {
      this.inputPrice = item;
      this.priceIdx = index;
    },
    // 获取配置信息 买单展示
    async getoilType() {
      try {
        const res = await CreditShop.offlineShow(
          this.qrcode_id,
          this.store_id,
          this.room_id
        );
        if (res.code == 200) {
          this.allInfor = res.data;
          this.setinitData(res.data);
        } else if (res.code == 400) {
          Dialog.alert({
            message: res.msg + "!返回首页"
          }).then(() => {
            let app_id = localStorage.getItem("app_id");
            this.$router.replace({
              path: "/vipHome",
              query: {
                app_id
              }
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async setinitData(data) {
      if (data.goods.length == 0 || data.goods == null || data.goods == "") {
        // 判断有无油枪
        this.$toast("未绑定油枪");
      } else {
        for (let i = 0; i < data.goods.length; i++) {
          const element = data.goods[i];
          if (element.goods) {
            this.goodslist.push(element);
          }
        }
        if (this.goodslist.length == 0) {
          // 判断油枪是否绑定油品
          this.$toast("油枪暂未绑定油品");
        } else {
          let mygood = this.goodslist[0];
          let info = {
            card_id: this.vipCard_id,
            type_id: mygood.goods.type_id,
            goods_id: mygood.goods.id
          };
          this.$refs.oilInfor.getoilsInfor(mygood, info);
          this.$refs.oilInfor.vipCard_id = this.vipCard_id;
          if (this.goodslist.length > 1) {
            //判断是否弹窗
            this.$refs.oilInfor.oilStatus = true;
            this.cash_order_status();
          }
        }
      }
      if (data.preferce_set) {
        //判断互斥
        this.offline_buy = data.preferce_set.offline_buy;
        this.judgeRule(data.preferce_set.offline_buy);
      } else {
        throw "暂未设置互斥规则";
      }
      if (data.recharge_set.length == 0) {
        this.recharge_state = false;
      } else {
        this.recharge_state = true;
      }
    },
    // 获取我的会员卡信息
    async MygetVipList() {
      try {
        const res = await UserInfo.my_card();
        if (res.code == 200) {
          this.vipCard_id = res.data.user_level_id;
          sessionStorage.setItem("vipCard_id", res.data.user_level_id);
          this.my_cardInfor = res.data;
        } else {
          throw res.msg;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 判断规则
    judgeRule(arr) {
      let Rule = arr.split(",");
      let data = this.allInfor.point_set.rule;
      this.ruleAmount = data.amount;
      this.ruleRate = data.rate;
      this.ruleMin = data.min;
      if (Rule.indexOf("1") != -1 && Rule.indexOf("2") != -1) {
        this.openMutex = true; //开启互斥
      } else {
        this.openMutex = false; //关闭互斥
      }
    },
    selectDiscounts(type, item = "") {
      if (type == "discounts") {
        if (this.useIntegral == true) {
          this.$refs.coupon.select_Cou(item);
          this.useIntegral = false;
        } else {
          this.$refs.coupon.select_Cou(item);
        }
      } else {
        this.payPrice = Number(this.payPrice) + Number(this.upPrice);
        this.couponIds = ""; //支付时的id
        this.useNum = 0;
        this.$refs.coupon.now_ticketId = ""; //选中优惠券为空
        this.bottom_ticket = "选择优惠券";
        this.upPrice = 0;
      }
    },
    openCouponsList() {
      if (this.inputPrice) {
        this.$refs.coupon.showCop = true;
      } else {
        this.$toast("请输入金额后选择");
      }
    },
    cashCancelDis() {
      //使用现金取消优惠
      this.useIntegral = false;
      setTimeout(() => {
        this.$refs.coupon.nonuseCoupon();
      }, 200);
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .submit-order {
    width: 100%;
    height: 55px;
    margin-top: -55px; // 与该元素高度相等
    background: rgba(255, 255, 255, 1);
    position: relative;
    bottom: 0;
    padding: 0 14px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .discounts-price {
      font-size: 12px;
      color: rgba(252, 112, 53, 1);
      line-height: 18px;
    }

    .pay-price-operation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .fontSize22 {
        font-size: 22px;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
      }
    }

    .submit {
      width: 110px;
      height: 40px;
      background: rgba(252, 112, 53, 1);
      border-radius: 20px;
      text-align: center;
      line-height: 40px;
      margin-left: 10px;
      color: #fff;
      font-size: 16px;
    }

    .nomoney {
      background: #bfbfbf;
    }
  }
}
.dis-fa {
  width:94%;margin: 10px auto
}
.newConfirmOrder {
  width: 100%;
  font-family: Noto Sans SC;
  font-style: normal;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 55px;
  background: #fafafc;  
  .shop-infor {
    height: 105px;
    background: rgba(255, 255, 255, 1);
    margin-top: 4px;
    padding: 16px 14px 18px;
    position: relative;

    .shop-infor-name {
      font-size: 17px;
      font-weight: 400;
      color: rgba(31, 31, 31, 1);
      line-height: 17px;
    }

    .shop-infor-address {
      font-weight: 400;
      color: rgba(136, 136, 136, 1);
      line-height: 12px;
      height: 12px;
      font-size: 12px;
      margin-top: 12px;
    }

    .shop-infor-price {
      margin-top: 15px;
      line-height: 15px;
      color: #666666;

      span {
        font-size: 18px;
        font-weight: 400;
        color: rgba(31, 31, 31, 1);
        margin-right: 15px;
      }
    }

    .shop-infor-head {
      width: 54px;
      height: 54px;
      position: absolute;
      right: 14px;
      top: 50%;
      margin-top: -27px;
      overflow: hidden;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .oils-Cont {
    padding: 0 14px;
    background: #fff;
    margin-top: 9px;

    .oils-Cont-title {
      display: flex;
      justify-content: space-between;
      height: 52px;
      font-size: 16px;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 52px;
      align-items: center;
    }

    .oils-Cont-price {
      height: 80px;
      background: rgba(25, 121, 211, 0.03);
      border-radius: 5px;
      border: 1px solid rgba(61, 120, 205, 1);
      padding-left: 20px;
      line-height: 80px;
      font-size: 38px;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      margin-bottom: 10px;
      display: flex;
      overflow: hidden;
      position: relative;

      input {
        width: 220px;
        background: #f8fbfe;
        border: 0;
      }

      span:nth-child(3) {
        font-size: 14px;
        color: #bdbdbd;
        position: absolute;
        right: 5px;
        top: 9px;
      }

      .inputSize {
        font-size: 14px;
        color: rgb(150, 151, 153);
      }
    }

    .oils-Cont-discounts {
      display: flex;
      justify-content: space-between;
      margin-right: -5px;

      .discounts-optiion {
        flex: 1;
        height: 60px;
        background: rgba(25, 121, 211, 0.03);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        color: rgba(61, 120, 205, 1);
        border: 1px solid rgba(61, 120, 205, 1);
        margin-right: 5px;

        .price {
          height: 20px;
          line-height: 20px;
          font-size: 16px;
          font-weight: 400;
        }
        .discounts-optiion-price {
          font-weight: 400;
          height: 18px;
        }
      }
    }
  }

  .cell_lft {
    display: flex;
    align-items: center;
    position: relative;
    img {
      width: 12px;
      margin-right: 8px;
    }
    .iconzhinengkefu- {
      font-size: 13px;
      margin-right: 8px;
      color: #339973;
    }
    .selected {
      position: absolute;
      width: 55px;
      height: 14px;
      line-height: 14px;
      font-size: 10px;
      right: -20px;
      top: 50%;
      margin-top: -8px;
      color: rgb(241, 106, 16);
      border: 1px solid rgb(241, 106, 16);
      text-align: center;
    }
  }

  .select-jifen {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .van-icon-close {
      margin-left: 5px;
    }

    .custom-title {
      margin-left: 5px;
    }
  }
}
</style>