<template>
  <div>
    <!--优惠券-->
    <Popup v-model="showCop" round position="bottom" style="height: 60%;">
      <div class="ticket-tab">
        <div
          :class="[now_tabIdx == idx ? 'active' : '']"
          v-for="(item, idx) in ticketType"
          :key="idx"
          :title="item"
          @click="getCoupons(idx,true)"
        >{{ item }}</div>
      </div>
      <p class="hint youhui_hint">温馨提示: 以上三种优惠券不能同时使用</p>
      <div class="ticket-list">
        <RadioGroup v-model="now_ticketId">
          <div
            class="ticket-item"
            v-for="(item, idx) in myCoupons"
            :key="item.id"
          >
            <!-- 如果互斥执行selectDiscounts('discounts') -->
            <div class="ticket-item-top">
              <div class="ticket-item-top-money">
                <!--满减券-->
                <h2 v-if="item.coupon_data.type == 1">
                  {{ item.coupon_data.m_dec_money }}
                  <span>元</span>
                </h2>
                <!--折扣券-->
                <h2 v-if="item.coupon_data.type == 2">
                  {{ item.coupon_data.zhe_discount }}
                  <span>折</span>
                </h2>
                <!--代金券-->
                <h2 v-if="item.coupon_data.type == 3">
                  {{ item.coupon_data.d_face_value }}
                  <span>元</span>
                </h2>
              </div>
              <div class="ticket-item-top-name">
                <p>{{ item.coupon_data.title }}</p>
                <p>{{ item.exp_time }}</p>
                <div v-if="item.coupon_data.type != 2">
                  <stepper
                    v-model="item.stepperVal"
                    @plus="useMulti(item,idx)"
                    @minus="useReduce(item)"
                    input-width="30px"
                    button-size="25px"
                    min="1"
                    :max="item.use_num < item.coupon_data.use_limit?item.use_num:item.coupon_data.use_limit"
                    :disabled="now_ticketId==item.id?false:true"/>
                </div>
                <div class="right-btn">
                  <Radio :name="item.id" @click="openMutex ? $parent.selectDiscounts('discounts', item) : select_Cou(item)"></Radio>
                </div>
              </div>
            </div>
            <div class="ticket-item-bottom">描述信息：该券单次最多可用{{item.coupon_data.use_limit}}张，剩余{{item.use_num}}张</div>
          </div>
        </RadioGroup>
      </div>
      <div class="nonuse-coupon">
        <Button type="danger" size="small" @click="nonuseCoupon" block>不使用优惠券</Button>
      </div>
    </Popup>
  </div>
</template>

<script>
import CreditShop from "@/api/creditshop";
import { Popup, Button, RadioGroup, Radio, Stepper } from "vant";
export default {
  props: ["inputPrice", "allInfor", "nowOilInfor", "openMutex", "offline_buy"],
  components: {
    Popup,
    Button,
    RadioGroup,
    Radio,
    Stepper 
  },
  data() {
    return {
      couponNum: 1,
      showCop: false,
      myCoupons: [], // 优惠券列表
      now_tabIdx: 0,
      ticketType: ["满减券", "兑换券", "代金券"],
      now_ticketId: "",
      useNum: 0,
    };
  },
  methods: {
    useMulti(item,idx) {
      setTimeout(() => {
        if (item.coupon_data.m_enough_money > 0) { // 右门槛
          if (item.coupon_data.m_enough_money * item.stepperVal > this.$parent.inputPrice) {
            this.$toast(`您最多可以使用${this.useNum}张`)
            this.myCoupons[idx].stepperVal--
            this.useNum=item.stepperVal
            this.set_radio(item);
          } else {
            this.useNum=item.stepperVal
            this.set_radio(item);
          }
        } else {
          if (item.coupon_data.m_dec_money * item.stepperVal> this.$parent.inputPrice) {
            this.$toast(`您最多可以使用${this.useNum}张`)
            this.myCoupons[idx].stepperVal--
            this.useNum=item.stepperVal
            this.set_radio(item);
          } else {
            this.useNum=item.stepperVal
            this.set_radio(item);
          }
        }
      },200)
    },
    useReduce(item) {
      setTimeout(() => {
        this.useNum=item.stepperVal
        this.set_radio(item);
      },200)
    },
    select_Cou(item) { 
      for (let i = 0; i < this.myCoupons.length; i++) {
        const element = this.myCoupons[i];
        element.stepperVal = 1;
      }
      this.useNum = item.stepperVal;
      this.set_radio(item);
    },
    // 获取优惠券
    async getCoupons(idx,present = false) {
      try {
        this.now_tabIdx = idx;
        this.myCoupons = [];
        // if (idx === 0) idx = -1;
        const res = await CreditShop.getAvailable(
          this.$parent.inputPrice,
          idx + 1,
          2,
          this.allInfor.room_id,
          this.$parent.nowOilInfor.goods.id
        );
        if (res.code == 200) {
          // 可使用优惠券数量
          if (res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              const element = res.data[i];
              if (element.id == this.now_ticketId) {
                element.stepperVal = this.useNum
              } else {
                element.stepperVal = 1
              }
              this.myCoupons.push(element);
            }
          }
        } else {
          // var str = this.$store.state.now_UserDisKey;
          this.$parent.useNum = 0;
          this.$parent.couponIds = "";
          this.$parent.bottom_ticket = "无优惠券";
          // if (!str) {
          //   str = this.offline_buy;
          // }
          // this.$parent.calculatePrice(this.inputPrice, "", "", str);
        }
        this.$parent.is_used_num = res.num;
        if(!present) { // 只有切换金额和油枪的时候才去设置默认
          this.setDefault()
        }
      } catch (error) {
        console.log(error);
      }
    },
    //设置默认优惠券
    judgeUse(predict, use_num, use_limit) {
      let ultimatelyNum = ""
      if (predict > use_num && use_num>use_limit) {           // 预计大于拥有且拥有大于最大使用
        ultimatelyNum = use_limit;
      } else if (predict > use_num && use_num < use_limit) {  // 预计大于拥有且拥有小于最大使用
        ultimatelyNum = use_num;
      } else if (predict > use_num && use_num == use_limit) { // 预计大于拥有且拥有 == 最大使用
        ultimatelyNum = use_num;
      } else if (predict < use_num && predict > use_limit) {  // 预计小于拥有且预计大于最大使用
        ultimatelyNum = use_limit;
      } else if (predict < use_num && predict < use_limit) {  // 预计 < 拥有且预计<最大使用
        ultimatelyNum = predict;
      } else if (predict < use_num && predict == use_limit) { // 预计 < 拥有且预计==最大使用
        ultimatelyNum = predict;
      } else if (predict == use_num && predict > use_limit) { // 预计 = 拥有且预计<最大使用
        ultimatelyNum = use_limit
      } else if (predict == use_num && predict < use_limit) { // 预计 = 拥有且预计<最大使用
        ultimatelyNum = predict;
      } else { // 预计=拥有且预计=最大使用
        ultimatelyNum = predict;
      }
      return ultimatelyNum;
    },
    setDefault() {
      let moneydata = [];
      let numData = [];
      let predict_uses = ""; // 有门槛预计
      let no_predict_uses = ""; // 无门槛预计
      let pitch_on = "" // 使用数量
      for (let i = 0; i < this.myCoupons.length; i++) {
        const element = this.myCoupons[i];
        if (element.coupon_data.m_enough_money > 0) { 
          //判断可使用数量,有门槛 预估使用
          predict_uses = parseInt(this.$parent.inputPrice/element.coupon_data.m_enough_money)
          pitch_on = this.judgeUse(predict_uses, element.use_num, element.coupon_data.use_limit)
        } else {
          //无门槛 预估使用
          no_predict_uses = parseInt(this.$parent.inputPrice/element.coupon_data.m_dec_money)
          pitch_on = this.judgeUse(no_predict_uses, element.use_num, element.coupon_data.use_limit)
        }
        numData.push(pitch_on);
        moneydata.push(element.coupon_data.m_dec_money * pitch_on);
      }
      for (let x = 0; x < moneydata.length; x++) {
        const element = moneydata[x];
        if(element == Math.max(...moneydata)) {
          this.useNum = numData[x]
          this.myCoupons[x].stepperVal = this.useNum
          if (Number(this.myCoupons[x].coupon_data.m_dec_money) > Number(this.$parent.inputPrice)) return; // 判断是否用券
          this.set_radio(this.myCoupons[x])
          break;
        }
      }
    },
    // 选择优惠券 点击显示单选按钮
    set_radio(item) {
      this.now_ticketId = item.id;
      switch (item.coupon_type) {
        // 满减券计算
        case 1:
          this.$parent.couponIds = item.coupon_data_ids.slice(0,this.useNum).join();
          this.$parent.useNum = this.useNum;
          this.$parent.upPrice = item.coupon_data.m_dec_money * this.useNum;
          this.$parent.bottom_ticket = `-${item.coupon_data.m_dec_money * this.useNum}元`;
          break;
        // 折扣券计算
        case 2:
          if (item.coupon_data.zhe_limit == 1) {
            // 是否开启了优惠券最大折扣金额
            this.$parent.bottom_ticket = `${item.coupon_data.zhe_discount}折`;
            if (
              (this.inputPrice * item.coupon_data.zhe_discount) / 10 > Number(item.coupon_data.zhe_limit_money)
            ) {
              this.$toast( `您使用的${item.coupon_data.zhe_discount}折优惠券,最大只能优惠${item.coupon_data.zhe_limit_money}元` );
              this.$parent.upPrice = Number(item.coupon_data.zhe_limit_money);
            } else {
              this.$parent.upPrice = Number(this.inputPrice) - (Number(this.inputPrice) * Number(item.coupon_data.zhe_discount)) / 10;
            }
          } else {
            this.$parent.bottom_ticket = `${item.coupon_data.zhe_discount}折`;
            this.$parent.upPrice = Number(this.inputPrice) - (Number(this.inputPrice) * Number(item.coupon_data.zhe_discount)) / 10;
          }
          this.$parent.couponIds = item.coupon_data_ids.slice(0,1).join();
          this.$parent.useNum = 1;
          break;
        // 代金券
        case 3:
          this.$parent.useNum = this.useNum;
          this.$parent.couponIds = item.coupon_data_ids.slice(0,this.useNum).join();
          this.$parent.bottom_ticket = `-${item.coupon_data.d_face_value* this.useNum}元`;
          this.$parent.upPrice = item.coupon_data.d_face_value* this.useNum;
          break;
        default:
          break;
      }
      this.$parent.calculatePrice(this.inputPrice, item.id, this.useNum);
    },
     // 取消使用优惠
    nonuseCoupon() {
      this.showCop = false;
      this.now_ticketId = "";
      this.$parent.bottom_ticket = "选择优惠券";
      this.$parent.upPrice = 0;
      this.myCoupons.forEach(item => item.stepperVal=1);
      this.useNum = 0
      this.$parent.useNum = "";//支付使用的也为空
      this.$parent.couponIds = "";//支付使用的也为空
      this.$store.commit('updateAllDisMoney', { dis_money: 0, end_money: 0});
    }
  }
};
</script>

<style lang="scss" scoped>
.ticket-tab {
  width: 100%;
  position: fixed;
  display: flex;
  user-select: none;
  background-color: #fff;
  border-bottom: 1px solid #dfdfdf;
  border-radius: 20px 20px 0 0;
  z-index: 200;

  div,
  .active {
    position: relative;
    flex: 1;
    box-sizing: border-box;
    min-width: 0;
    padding: 0 5px;
    color: #646566;
    font-size: 14px;
    line-height: 44px;
    text-align: center;
    cursor: pointer;
  }

  .active {
    color: #ee0a24;
  }
}

.youhui_hint {
  margin-top: 45px;
  text-align: center;
}

.nonuse-coupon {
  height: 40px;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 30px;
}

.ticket-list {
  width: 100%;
  height: 320px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding-top: 5px;

  .ticket-item {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    display: flex;
    overflow: hidden;
    margin-top: 10px;
    padding-top: 14px;
    flex-direction: column;
    justify-content: space-between;

    .ticket-item-top {
      width: 100%;
      min-height: 84px;
      border-bottom: 1px dashed #ebedf0;
      display: flex;
      padding: 6px 10px;
      justify-content: space-between;

      .ticket-item-top-money {
        position: relative;
        min-width: 80px;
        padding: 0 8px;
        color: #ee0a24;
        text-align: center;
        color: #ee0a24;

        h2 {
          margin-bottom: 6px;
          font-weight: 500;
          font-size: 16px;
          overflow: hidden;

          span {
            font-weight: normal;
            font-size: 60%;
          }
        }

        p {
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 12px;
          line-height: 16px;
          white-space: pre-wrap;
        }
      }

      .ticket-item-top-name {
        flex: 1;
        min-width: 220px;
        height: 100%;
        position: relative;

        p:nth-child(1) {
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
        }

        p {
          margin-bottom: 10px;
        }

        .right-btn {
          position: absolute;
          top: 0;
          right: 0px;
          bottom: 0;
          display: flex;
          align-items: center;
        }
      }
    }

    .ticket-item-bottom {
      padding: 6px;
    }
  }

  .bottom-text {
    width: 100%;
    height: 25px;
    position: fixed;
    text-align: center;
    line-height: 25px;
    font-size: 14px;
    left: 0;
    color: #000000;
    bottom: 0;
    background: #ecf0f1;
  }
}
</style>